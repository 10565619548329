<template>
  <div ref="all" class="all">
    <div class="top">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_subsidies_all.png"/>
    </div>
    <div class="bottom" :style="{display: click ? '' : 'none'}">
      <template v-for="(item, index) in subsidiesList">
        <div v-if="item.image" class="content" :key="index">
          <img :src="item.image" @click="jump(item.name)"/>
        </div>
      </template>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Icon, Toast } from 'vant'
import Loading from '../../components/loading'

Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)

export default {
  name: 'all',
  data () {
    return {
      token: '',
      version: '',
      channel: '',
      appClient: '',
      click: false,
      subsidiesItem: {},
      subsidiesList: [
        {
          image: 'https://cdn.cailu88.com/jingxuanshi/client_subsidies_jhs_1.jpg',
          name: 'jhs'
        },
        {
          image: 'https://cdn.cailu88.com/jingxuanshi/client_subsidies_pdd_1.jpg',
          name: 'pdd'
        },
        // {
        //   image: 'https://cdn.cailu88.com/jingxuanshi/client_subsidies_jd.png',
        //   name: 'jd'
        // },
        // {
        //   image: 'https://cdn.cailu88.com/jingxuanshi/client_subsidies_jx.png',
        //   name: 'jx'
        // },
        {
          image: 'https://cdn.cailu88.com/jingxuanshi/client_subsidies_wph_1.jpg',
          name: 'wph'
        },
        {
          image: 'https://cdn.cailu88.com/jingxuanshi/client_subsidies_sn_1.jpg',
          name: 'sn'
        },
      ]
    }
  },
  components: {
    Loading,
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    this.appClient = this.$route.query.appClient
  },
  mounted () {
    setTimeout(() => {
      this.click = true
    }, 500)
  },
  methods: {

    // 进入会场
    jump (value) {
      let routerName = value == 'jhs' ? 'subsidies' : value == 'jx' || value == 'jd'? 'template' : value == 'pdd' ? 'pddSubsidies' : value == 'sn' ? 'snSubsidies' : value == 'wph' ? 'wphSubsidies' : ''
      let params = {
          token: this.token,
          version: this.version,
          channel: this.channel,
          appClient: this.appClient
      }
      if (value == 'jx') {
        params.activityId = '306'
      } else if (value == 'jd') {
        params.activityId = '307'
      }
      this.$router.push({
        name: routerName,
        query: params
      })
    },
  },
}
</script>
<style lang="less" scoped>
.all {
  min-height: 100vh;
  background: #FB3337;

  .top {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .content {
      line-height: 0;
      margin-bottom: 8px;
      width: 345px;
      background: #393939;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
}
</style>
